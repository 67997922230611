import { CoursePageService } from 'src/app/sections/courses/services/course-page.service';
import { CommonDataService } from './../../../sections/common/services/common-data.service';
import { Component, Input } from '@angular/core';
import { CompanyLogoConfiguration } from 'src/app/models/auxiliary/company-logo-configuration';

@Component({
  selector: 'app-courses-list-report',
  templateUrl: './courses-list-report.component.html',
  styleUrls: ['./courses-list-report.component.scss']
})
export class CoursesListReportComponent {
  @Input() reportId: string;
  @Input() data: [];
  @Input() localeId: number;
  companyLogo: CompanyLogoConfiguration = null;



  getCourseCategories(courseCcategoryList: any[]) {
    return courseCcategoryList.map(e => this.commonDataService.getAuxillaryTranslateById("courseCategoryList", e, this.coursePageService.localeId)).join(", ");
  }

  date = new Date();
  constructor(public commonDataService: CommonDataService,public coursePageService:CoursePageService) { 
    this.companyLogo = new CompanyLogoConfiguration();
  }

  ngOnInit() {
    this.commonDataService.getCompanyLogo().subscribe((labConfiguration: CompanyLogoConfiguration) => {
      this.companyLogo = labConfiguration;
    });
  }
}
