import { Injectable } from '@angular/core';
import { Prescription } from 'src/app/models/dashboard/prescription.model';
import { PrescriptionEndPoints } from 'src/app/constants/api-endpoints';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/sections/common/services/api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {

  prescriptionUrl = PrescriptionEndPoints;

  constructor(private _apiService : ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  // Prescription API

  getPrescriptionById(id: number): Observable<Prescription> {
    return this._apiService.get(this.prescriptionUrl.getPrescriptionById + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getPrescriptionList(): Observable<Prescription> {
    return this._apiService.get(this.prescriptionUrl.getPrescriptionList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  savePrescription(prescription: Prescription): Observable<Prescription> {
    return this._apiService.post(this.prescriptionUrl.savePrescription, prescription, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updatePrescription(id: number, prescription: Prescription): Observable<Prescription> {
    return this._apiService.put(this.prescriptionUrl.updatePrescription + id, prescription, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deletePrescription(id: number): Observable<Prescription> {
    return this._apiService.delete(this.prescriptionUrl.deletePrescription, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getPrescriptionsListWithPagination(pageSize: number, page: number, filter) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', (filter.dateFrom || "") + ';' + (filter.dateTo || "") + ';' + filter.doctorId + ';' + (filter.status || ""));
    const query = params.toString().replace('+', '%20');
    return this._apiService.get(this.prescriptionUrl.getPrescriptionsListWithPagination + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  prescriptionListExport(pageSize: number, page: number, filter) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', (filter.dateFrom || "") + ';' + (filter.dateTo || "") + ';' + filter.doctorId + ';' + (filter.status || ""));
    const query = params.toString().replace('+', '%20');
    return this._apiService.get(this.prescriptionUrl.prescriptionListExport + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
