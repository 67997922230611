import { Injectable } from '@angular/core';
import { EventPoints } from 'src/app/constants/api-endpoints';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Event } from 'src/app/models/dashboard/event.model';
import { ApiService } from 'src/app/sections/common/services/api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  eventUrls = EventPoints;

  constructor(private _apiService: ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }


  getEventById(id: number): Observable<Event> {
    return this._apiService.get(this.eventUrls.getEventById + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getEventList(): Observable<Event> {
    return this._apiService.get(this.eventUrls.getEventList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveEvent(event: Event): Observable<Event> {
    return this._apiService.post(this.eventUrls.saveEvent, event, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateEvent(id: number, event: Event): Observable<Event> {
    return this._apiService.put(this.eventUrls.updateEvent + id, event, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteEvent(id: number): Observable<Event> {
    return this._apiService.delete(this.eventUrls.deleteEvent, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getEventsListWithPagination(pageSize: number, page: number, filter) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', (filter.dateFrom || "") + ';' + (filter.dateTo || "") + ';' + (filter.timeFrom || "") + ';' + (filter.timeTo || "") + ';' + filter.timeType + ';' + filter.eventType + ';' + (filter.eventName || ""));
    const query = params.toString().replace('+', '%20');
    return this._apiService.get(this.eventUrls.getEventsListWithPagination + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  eventListExport(pageSize: number, page: number, filter) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', (filter.dateFrom || "") + ';' + (filter.dateTo || "") + ';' + (filter.timeFrom || "") + ';' + (filter.timeTo || "") + ';' + filter.timeType + ';' + filter.eventType + ';' + (filter.eventName || ""));
    const query = params.toString().replace('+', '%20');
    return this._apiService.get(this.eventUrls.eventListExport + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }
}
