import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-modal-basic',
  templateUrl: './modal-basic.component.html',
  styleUrls: ['./modal-basic.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalBasicComponent {
  @Input() dialogClass: string;
  @Input() modalId?: string; //optional
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  public visible = false;
  public visibleAnimate = false;

  public show(): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }

  public onContainerClicked(_event: MouseEvent): void { 
  }
}
