import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ngx-loading-spinner',
  templateUrl: './ngx-loading-spinner.component.html',
  styleUrls: ['./ngx-loading-spinner.component.scss']
})
export class NgxLoadingSpinnerComponent {
  @Input() isSpinnerVisible: boolean;
  spinnerColor = '#448aff';

}
