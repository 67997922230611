import { Component, Input } from '@angular/core';
import { CompanyLogoConfiguration } from 'src/app/models/auxiliary/company-logo-configuration';
import { CommonDataService } from 'src/app/sections/common/services/common-data.service';

@Component({
  selector: 'app-clinic-list-report',
  templateUrl: './clinic-list-report.component.html',
  styleUrls: ['./clinic-list-report.component.scss']
})
export class ClinicListReportComponent {

  @Input() reportId: string;
  @Input() printClinicList: [];
  companyLogo: CompanyLogoConfiguration = null;

  date= new Date();
  constructor(public commonDataService: CommonDataService) {
    this.companyLogo = new CompanyLogoConfiguration();
  }

  ngOnInit() {
    this.commonDataService.getCompanyLogo().subscribe((labConfiguration: CompanyLogoConfiguration) => {
      this.companyLogo = labConfiguration;
    });
  }
}
