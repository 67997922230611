import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ModalBasicComponent],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [ModalBasicComponent]
})
export class ThemeModule { }
