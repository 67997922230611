import { Town } from './doctor-town.model';
import { Province } from './province.model';
import { Country } from '../country.model';
import { Doctor } from './doctor.model';

export class DoctorProfessionalAddress {

  id: number;
  name: string;
  personalTelephone: string;
  workTelephone: string;
  mobileNumber: string;
  fax: string;
  email: string;
  web: string;
  number: string;
  typeOfVia: string;
  nameOfVia: string;
  door: string;
  postalCode: string;
  floorNumberOfBuilding: string;
  province: Province;
  provinceId: number;
  provinceName: string;
  town: Town;
  townId: number;
  townName: string;
  country: Country;
  countryId: number;
  countryName: string;
  isDefault: boolean;
  doctorId: number;
  doctor: Doctor;

  constructor() {
    this.province = new Province();
    this.town = new Town();
    this.country = new Country();
    this.doctor = new Doctor();

  }
}
