import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CorrespondenceDocument } from 'src/app/models/correspondence/correspondence-document.model';

@Component({
  selector: 'app-view-correspondence-document',
  templateUrl: './view-correspondence-document.component.html',
  styleUrls: ['./view-correspondence-document.component.scss']
})
export class ViewCorrespondenceDocumentComponent {
  @ViewChild('modalLargeNew', { static: false }) child;
  submitted = false;
  correspondenceDocument: CorrespondenceDocument;

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) {
    this.correspondenceDocument = new CorrespondenceDocument();
  }

  public show(correspondenceDocument: CorrespondenceDocument) {
    if (correspondenceDocument) {
      if (correspondenceDocument != null) {
        this.correspondenceDocument = correspondenceDocument;
        this.correspondenceDocument.safeUrl = this.correspondenceDocument.url
          ? this.sanitizer.bypassSecurityTrustResourceUrl(this.correspondenceDocument.url) : undefined;
        this.child.show();
        this.submitted = false;
      }
    }
  }

  onClickCorrespondenceDocument(correspondenceDocument: CorrespondenceDocument) {
    if (correspondenceDocument) {
      window.open(correspondenceDocument.link, '_blank');
    }
  }

}
