import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Clinic } from '../../../models/clinic.model';
import { ClinicEndPoints } from '../../../constants/api-endpoints';
import { HttpParams } from '@angular/common/http';
import { Doctor } from '../../../models/doctor/doctor.model';
import { ApiService } from '../../common/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {
  clinicUrl = ClinicEndPoints;
  isLoading = false;

  constructor(private _apiService : ApiService) {
  }

  private returnResponse(response){
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  getClinicById(id: number): Observable<Clinic> {
    return this._apiService.get(this.clinicUrl.getClinicById + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getCliniclist(): Observable<Clinic> {
    return this._apiService.get(this.clinicUrl.getClinicList, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  saveClinic(clinic: Clinic): Observable<Clinic> {
    return this._apiService.post(this.clinicUrl.saveClinic, clinic, true, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateClinic(id: number, clinic: Clinic): Observable<Clinic> {
    return this._apiService.put(this.clinicUrl.updateClinic + id, clinic, true, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteClinic(id: number): Observable<Clinic> {
    return this._apiService.delete(this.clinicUrl.deleteClinic, id.toString(), true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getClinicsPaginated(pageSize: number, page: number, filters: any): Observable<Clinic> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', (filters.clinicName || "") + ';' + (filters.telephone || "") + ';' + (filters.userRido || "") + ';' + filters.townId + ';' + filters.doctorId + ';' + (filters.email || ""));
      const query = params.toString().replace('+', '%20');

    return this._apiService.get(this.clinicUrl.getClinicListPaginated + '?' + `${query}`, true).pipe(
       map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDoctorListByClinicId(id: number): Observable<Doctor> {
    return this._apiService.get(this.clinicUrl.getDoctorListByClinicId + id, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  clinicListExport(pageSize: number, page: number, filters: any): Observable<Clinic> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', (filters.clinicName || "") + ';' + (filters.telephone || "") + ';' + (filters.userRido || "") + ';' + filters.townId + ';' + filters.doctorId + ';' + (filters.email || ""));
      const query = params.toString().replace('+', '%20');
      return this._apiService.get(this.clinicUrl.clinicListExport + '?' + `${query}`, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

}
