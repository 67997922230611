import {NgbDateStruct, NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';

@Injectable()
export class NgbDateFormatOutput extends NgbDateAdapter<NgbDateStruct> {
  fromModel(value): NgbDateStruct {
    if (!value) {
      return null;
    }

    if (!value.year) {
      const tempValue = new Date(value);
      return {
        year: tempValue.getFullYear(),
        month: tempValue.getMonth() + 1,
        day: tempValue.getDate()
      };
    }
    return value;
  }

  toModel(date: NgbDateStruct): NgbDateStruct {
    return date;
  }
}
