import { Component, Input, OnInit } from '@angular/core';
import { CompanyLogoConfiguration } from 'src/app/models/auxiliary/company-logo-configuration';
import { CommonDataService } from 'src/app/sections/common/services/common-data.service';

@Component({
  selector: 'app-society-expand-list-report',
  templateUrl: './society-expand-list-report.component.html',
  styleUrls: ['./society-expand-list-report.component.scss']
})
export class SocietyExpandListReportComponent implements OnInit {
  @Input() data: [];
  companyLogo: CompanyLogoConfiguration = null;
  date = new Date();

  constructor(public commonDataService: CommonDataService) {
    this.companyLogo = new CompanyLogoConfiguration();
  }

  ngOnInit() {
    this.commonDataService.getCompanyLogo().subscribe((labConfiguration: CompanyLogoConfiguration) => {
      this.companyLogo = labConfiguration;
    });
  }

}
