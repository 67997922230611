import { Injectable, Compiler } from '@angular/core';
import { User } from '../../../models/user.model';
import { Observable } from 'rxjs';
import { AuthenticationEndPoints, UserEndPoints } from '../../../constants/api-endpoints';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../../common/services/api.service';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  helper = new JwtHelperService();
  authenticationUrl = AuthenticationEndPoints;
  userUrl = UserEndPoints;
  isLogin: any;

  constructor(
     private router: Router, 
     private compiler: Compiler,
     private _apiService : ApiService) {}

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  login(user: User): Observable<User> {
    return this._apiService.post(this.authenticationUrl.login, user, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  recoverPassword(email: string) {
    let url = this.authenticationUrl.forgotPassword;
    url = url.replace('{email}', email);
    return this._apiService.get(url).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  createPassword(user: User) {
    return this._apiService.post(this.userUrl.createPassword, user, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.isLogin = false;
    this.compiler.clearCache();
    this.router.navigate(['/login']);
  }


  public isAuthenticated(): boolean {
    return !helper.isTokenExpired(this.getAccessToken());
  }

  public setAccessToken(token): void {
    sessionStorage.setItem('isLogin', 'true');
    localStorage.setItem('isLogin', 'true');
    sessionStorage.setItem('access-token', token);
  }

  public getAccessToken(): string {
    return sessionStorage.getItem('access-token');
  }

  public setUserDetails(userDetails): void {
    localStorage.setItem('userDetails', userDetails);
  }

  getSessionIsLogin() {
    return sessionStorage.getItem('isLogin');
  }

  getLoaclStorageIsLogin() {
    return localStorage.getItem('isLogin');
  }

  getIsLogOut() {
    return localStorage.getItem('isLogOut');
  }

  public getUserDetails(): object {
    return JSON.parse(localStorage.getItem('userDetails'));
  }

  public clearAccessToken() {
    sessionStorage.removeItem('isLogin');
    localStorage.removeItem('isLogin');
    sessionStorage.removeItem('access-token');
  }

  public getTokenData() {
    return helper.decodeToken(this.getAccessToken());
  }
}
