import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export function changeNgbDateToCustomFormat(ngbDate): any {
  if (ngbDate.year) {
    return ngbDate.year + '-' + ngbDate.month + '-' + ngbDate.day;
  }
  return ngbDate;
}

export function ngbToJsDate(ngbDate: NgbDate): Date {
  return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
}


export function toNgbDate(date: Date): NgbDate {
  return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
}
