export class VatTypes {
    id: number;
    description: string;
    iva: number;
    default: boolean;
    fecInactive: any;
    status: boolean;
    isEdit: boolean;
  
    fecInactiveView?: any;
  
    constructor() {
      this.status = true;
      this.default = false;
    }
  }
  