import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';
import { CancelInvoiceDetail } from './../../../models/administrative-documents/cancel-invoice-detail.model';
import { CancelInvoice } from './../../../models/administrative-documents/cancel-invoice.model';
import { AuxillaryService } from 'src/app/sections/common/services/auxillary.service';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownOption } from 'src/app/models/common/dropdown-option';
import { SortList } from '../../common/sortList';
import { TranslateService } from '@ngx-translate/core';
import { SeriesGroupService } from '../../auxillary-tables/series-year/Services/series-group.service';
import { IssuerService } from '../../auxillary-tables/issuer/services/issuer.service';
import { ToastrService} from 'ngx-toastr';
import { Invoice } from 'src/app/models/administrative-documents/invoice.model';
import { TSeries } from 'src/app/models/auxiliary/tseries.model';
import { CancelInvoiceService } from './services/cancel-invoice.service';
import { Delay } from '../../common/delay-helper';

@Component({
  selector: 'app-cancel-invoice',
  templateUrl: './cancel-invoice.component.html',
  styleUrls: ['./cancel-invoice.component.scss']
})
export class CancelInvoiceComponent implements OnInit {
  @ViewChild('modalLargeNew', { static: false }) modal;
  @Output() handleSave: EventEmitter<any> = new EventEmitter();

  issuersList: DropDownOption[] = [];
  seriesList: DropDownOption[] = [];
  cancelReasonsList: DropDownOption[] = [];
  selectedEconomicActsList: number[] = [];

  isLoading = false;
  submitted = false;

  formGroup: FormGroup;
  selectedInvoice: Invoice;

  issuerFormControl = new FormControl();
  seriesFormControl = new FormControl();
  cancelReasonFormControl = new FormControl();

  maxNumberSize = 5;

  validationMessages = {  }

  constructor(
    private seriesGroupService: SeriesGroupService, private issuerService: IssuerService, private _fb: UntypedFormBuilder, private cancelInvoiceService: CancelInvoiceService,
    private toastService: ToastrService, private translationService: TranslateService, private auxillaryService: AuxillaryService, private commonUIService: CommonUIService
  ) {
  }

  ngOnInit() {
    this.initializeForm();
    this.getCancelReasons();
  }

  initializeForm() {
    this.formGroup = this._fb.group({
      generatedNo: [null, [Validators.required]],
      reasonForCancelId: [null, [Validators.required]],
      issuerId: [this.selectedInvoice ? this.selectedInvoice.issuerId : null, [Validators.required]],
      seriesId: [null, [Validators.required]],
      invoiceId: [this.selectedInvoice ? this.selectedInvoice.id : null, [Validators.required]],
      observation: [null, [Validators.required]]
    });

  }

  public async show(invoice: Invoice) {
    this.seriesGroupService.getTseriesListBySelectedYear();
    await Delay(500);
    this.selectedInvoice = invoice;
    this.selectedEconomicActsList = invoice.invoiceDetail.map(id=>id.economicActId);
    this.initializeForm();
    this.getIssuers();
    this.submitted = false;
    this.issuerFormControl.setValue(invoice.issuerId);
    this.onIssuerSelected(this.selectedInvoice.issuerId);
    this.seriesFormControl.setValue(null);
    this.cancelReasonFormControl.setValue(null);
    this.modal.show();
  }


  getIssuers() {
    this.issuersList = [];
    this.issuerService.getIssuersList().subscribe(
      (data: any) => {
        this.issuersList = SortList(data._data, 'name').map(issuer => {
          return new DropDownOption(issuer.id, issuer.issuerDescription)
        });
        if (this.selectedInvoice) {
          this.issuerFormControl.setValue(this.selectedInvoice.issuerId);
          this.onIssuerSelected(this.selectedInvoice.issuerId);
        }

      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  getCancelReasons() {
    this.cancelReasonsList = [];
    this.auxillaryService.getCancelReasonList(true).subscribe(
      (data: any) => {
        this.cancelReasonsList = SortList(data._data, 'description').map(cancelReason => {
          return new DropDownOption(cancelReason.id, cancelReason.description)
        });
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  onIssuerSelected(issuer) {
    this.seriesList = this.seriesGroupService.seriesList.filter(series => series.issuerId == issuer && series.seriesType == "Invoice Cancel" && series.status).map(series => {
      return new DropDownOption(series.id, series.series)
    });
    this.formGroup.controls.issuerId.setValue(issuer);
    this.formGroup.controls.seriesId.setValue(null);
  }

  onCancelReasonSelected(cancelReason) {
    this.formGroup.controls.reasonForCancelId.setValue(cancelReason);
  }

  onSeriesSelected(selection) {
    if (!selection) return
    const series: TSeries = this.seriesGroupService.seriesList.find(s => s.id == selection)
    const prefix = series.series.toUpperCase() + '-';
    const _year = parseInt(series.year) > 0 ? series.year.slice(-2) : new Date().getFullYear().toString().slice(-2);
    const yearSuffix = '/' + _year;
    const numToBeUsed = series.lastNumber + 1;
    const gno = prefix + numToBeUsed.toString().padStart(this.maxNumberSize, '0') + yearSuffix;
    this.formGroup.controls.generatedNo.setValue(gno);
    this.formGroup.controls.seriesId.setValue(selection);
  }

  onClose() {
    this.modal.hide();
  }

  onSave() {
    this.submitted = true;
    if (!this.formGroup.controls.issuerId.value) {
      this.formGroup.controls.issuerId.setValue(null);
    }
    if (!this.formGroup.controls.seriesId.value) {
      this.formGroup.controls.seriesId.setValue(null);
    }
    if (!this.formGroup.controls.reasonForCancelId.value) {
      this.formGroup.controls.reasonForCancelId.setValue(null);
    }
    if (!this.commonUIService.validateForm(this.formGroup, this.validationMessages)) {
      return;
    }
    this.saveInvoice(this.formGroup.value);
  }

  handleClose() {
    this.selectedEconomicActsList = [];
    this.modal.hide();
    this.isLoading = false;

  }

  saveInvoice(invoice: CancelInvoice) {
    invoice.id = 0;
    invoice.invoiceCancellationDetail = this.selectedEconomicActsList.map(ea => {
      const invoiceDetail = new CancelInvoiceDetail();
      invoiceDetail.economicActId = ea;
      return invoiceDetail;
    });
    this.isLoading = true;
    this.cancelInvoiceService.saveInvoice(invoice).subscribe(
      (data: any) => {
        this.successToast('common.savedSuccessfully');
        this.handleClose();
        this.handleSave.emit(data._data);
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  get generatedNo() {
    return this.formGroup.get('generatedNo');
  }

  get issuerId() {
    return this.formGroup.get('issuerId');
  }

  get seriesId() {
    return this.formGroup.get('seriesId');
  }

  get reasonForCancelId() {
    return this.formGroup.get('reasonForCancelId');
  }

  get observation() {
    return this.formGroup.get('observation');
  }
}
