import { Injectable } from '@angular/core';
import { UploadFileDataEndPoints } from '../../../constants/api-endpoints';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UploadFileDataService {

  uploadFileDataUrls = UploadFileDataEndPoints;

  constructor(private _apiService : ApiService) { }

  uploadFileData(formData: FormData) {
    return this._apiService.post(this.uploadFileDataUrls.uploadFileData, formData, true, true).pipe(
      map((response: any) => {
        if (response.status > 399) {
          throw (response);
        }
        return response;
      }));
  }
}
