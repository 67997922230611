import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { RecallType } from 'src/app/models/dashboard/recall-type.model';
import { RecallCategory } from 'src/app/models/auxiliary/recall-category.model';
import { Recall } from 'src/app/models/dashboard/recall.model';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

import { RecallPageService } from '../services/recall-page.service';
import { AuxillaryService } from '../../common/services/auxillary.service';
import { RecallClass } from 'src/app/models/dashboard/recall-class.model';
import * as moment from 'moment';
import { changeNgbDateToCustomFormat } from '../../common/services/change-ngb-date-to-custom-format';
import { SortList } from '../../common/sortList';
import { RecallFormOfContact } from 'src/app/models/dashboard/recall-form-of-contact.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-recall',
  templateUrl: './add-edit-recall.component.html',
  styleUrls: ['./add-edit-recall.component.scss']
})
export class AddEditRecallComponent implements OnInit {

  @ViewChild('modalLargeNew', { static: false }) child;
  @Input() isAll: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();

  submitted = false;
  recall: Recall;
  recallTypeList: RecallType[];
  recallCategoryList: RecallCategory[];
  recallClassList: RecallClass[];
  recallFormOfContactList: RecallFormOfContact[];

  recallForm: FormGroup;
  doctorId: number;
  loggedUserId = 0;
  loggedUserName = '';
  recallUserName = '';
  isEdit: boolean;
  isLoading = false;

  validationMessages = {  }

  constructor(private formBuilder: UntypedFormBuilder, private recallService: RecallPageService, private auxillaryService: AuxillaryService,
    private toastService: ToastrService, private translationService: TranslateService, private authService: AuthService,
    private commonUIService:CommonUIService) {
    this.recall = new Recall();
    this.recallTypeList = [] as RecallType[];
    this.recallCategoryList = [] as RecallCategory[];
    this.recallClassList = [] as RecallClass[];
    this.initializeForAddRecall();
    const userDetails = this.authService.getTokenData();
    if (userDetails) {
      this.loggedUserId = userDetails.userId;
      this.loggedUserName = userDetails.userName;
      this.recallUserName = userDetails.userName;
    }
  }

  ngOnInit() {
    this.getRecallTypeList();
    this.getRecallCategoryList();
    this.getRecallClassList();
    this.getrecallFormOfContactList();
  }

  initializeForAddRecall() {
    this.recallForm = this.formBuilder.group({
      id: [''],
      userId: [this.loggedUserId, [Validators.required]],
      recallTypeId: ['', [Validators.required]],
      recallCategoryId: [''],
      description: [''],
      recallDate: ['', [Validators.required]],
      recallStatus: [false],
      institutionOrPerson: [''],
      recallClassId: [1],
      recallFormOfContactId: [''],
      time: ['']
    });
  }

  initializeForEditRecall() {
    const recallDate = moment(this.recall.recallDate).format('YYYY-MM-DD');
    this.recallForm = this.formBuilder.group({
      id: [this.recall.id],
      userId: [this.recall.userId ? this.recall.userId : this.loggedUserId, [Validators.required]],
      recallDate: [recallDate, [Validators.required]],
      recallTypeId: [this.recall.recallTypeId, [Validators.required]],
      recallCategoryId: [this.recall.recallCategoryId],
      description: [this.recall.description],
      recallStatus: [this.recall.recallStatus],
      institutionOrPerson: [this.recall.institutionOrPerson],
      recallClassId: [this.recall.recallClassId],
      recallFormOfContactId: [this.recall.recallFormOfContactId],
      time: [this.recall.time]
    });
    this.recallUserName = this.recall.userId ? this.recall.userName : this.loggedUserName;
  }

  getRecallClassList() {
    this.recallClassList = [
      { id: 1, RecallClassName: 'collegiate', status: true },
      { id: 2, RecallClassName: 'personal', status: true }
    ];
  }

  getRecallTypeList() {
    this.auxillaryService.getRecallTypeList().subscribe(
      (data: any) => {
        if (data._data != null) {
          this.recallTypeList = SortList(data._data, 'recallTypeName');
        }
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  getrecallFormOfContactList() {
    this.auxillaryService.getRecallFormOfContactList(true).subscribe(
      (data: any) => {
        if (data._data != null) {
          this.recallFormOfContactList = SortList(data._data, 'formOfContact');
        }
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }


  getRecallCategoryList() {
    this.auxillaryService.getRecallCategoryList().subscribe(
      (data: any) => {
        if (data._data != null) {
          this.recallCategoryList = SortList(data._data, 'recallCategoryName');
        }
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }


  onSave() {
    this.submitted = true;
    if (!this.commonUIService.validateForm(this.recallForm, this.validationMessages)) {
      return;
    }
    if (!this.recallForm.controls.recallTypeId.value) {
      this.recallForm.controls.recallTypeId.setValue(null);
    }
    if (!this.recallForm.controls.recallCategoryId.value) {
      this.recallForm.controls.recallCategoryId.setValue(null);
    }
    if (!this.recallForm.controls.recallClassId.value) {
      this.recallForm.controls.recallClassId.setValue(null);
    }
    if (!this.recallForm.controls.recallFormOfContactId.value) {
      this.recallForm.controls.recallFormOfContactId.setValue(null);
    }
    if (this.isEdit) {
      this.updateRecall(this.recallForm.value);
      return;
    }
    this.saveRecall(this.recallForm.value);
  }

  private returnResponse() {
    this.close.emit(this.isAll);
    this.child.hide();
    this.isLoading = false;
  }

  saveRecall(recall: Recall) {
    recall.id = 0;
    recall.createdDate = new Date();
    recall.createdBy = this.loggedUserId;
    this.isLoading = true;
    recall.recallDate = changeNgbDateToCustomFormat(recall.recallDate).toString();
    recall.time = recall.time.toString();
    this.recallService.saveRecall(recall).subscribe(
      (_data: any) => {
        this.successToast('recallSave');
        this.returnResponse();
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  updateRecall(recall: Recall) {
    recall.id = this.recall.id;
    recall.modifiedDate = new Date();
    recall.modifiedBy = this.loggedUserId;
    recall.createdBy = this.recall.createdBy;
    recall.createdDate = this.recall.createdDate;
    this.isLoading = true;
    recall.recallDate = changeNgbDateToCustomFormat(recall.recallDate).toString();
    this.recallService.updateRecall(recall.id, recall).subscribe(
      (_data: any) => {
        this.successToast('recallUpdate');
        this.returnResponse();
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  public show(recallData): void {
    this.child.show();
    this.submitted = false;
    this.recall = recallData;
    this.recallForm.reset();
    this.checkIfEditOrAdd();
  }

  checkIfEditOrAdd() {
    if (!this.recall.id) {
      this.isEdit = false;
      this.initializeForAddRecall();
      return;
    }
    this.isEdit = true;
    this.initializeForEditRecall();
  }

  get recallTypeId() {
    return this.recallForm.get('recallTypeId');
  }
  get recallStatus() {
    return this.recallForm.get('recallStatus');
  }
  get description() {
    return this.recallForm.get('description');
  }
  get recallDate() {
    return this.recallForm.get('recallDate');
  }
  get recallCategoryId() {
    return this.recallForm.get('recallCategoryId');
  }
  get recallClassId() {
    return this.recallForm.get('recallClassId');
  }
  get institutionOrPerson() {
    return this.recallForm.get('institutionOrPerson');
  }
  get time() {
    return this.recallForm.get('time');
  }
  get recallFormOfContactId() {
    return this.recallForm.get('recallFormOfContactId');
  }
}
