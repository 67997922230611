import { Certificate } from '../auxiliary/certificate.model';
import { CourseCategory } from '../auxiliary/course-category.model';
import { CourseStatus } from '../auxiliary/course-status.model';
import { CourseType } from '../auxiliary/course-type.model';
import { VatTypes } from '../auxiliary/vatypes';
import { CourseCcategory } from '../course/course-ccategory.model';
import { CourseModule } from '../course/course-module.model';
import { CourseOrganization } from '../course/course-organization';
export class Course {
    id: number;
    courseDenomination: string;
    instructor: string;
    certificateId: number;
    academicCourse: string;
    courseTypeId: number;
    notes: string;
    courseStatusId: number;
    courseCategoryId: number;
    numberOfModules: number;
    courseOrganizationId: number;
    entryDate: Date;
    modifiedDate: Date;
    enteredBy: number;
    modifiedBy: number;
    tvatTypeId: number;

    allModuleCount: number;
    completedModuleCount: number;
    todayModuleName: string;
    lastIndex: number;
    isPriceListShow: boolean;
    isSave: boolean;

    certificate: Certificate;
    courseCategory: CourseCategory;
    courseStatus: CourseStatus;
    courseType: CourseType;
    courseModule: CourseModule[];
    courseCcategory: CourseCcategory[];
    courseCcategoryList: [];
    courseOrganization: CourseOrganization;
    tvatType: VatTypes;

    moduleDates?: string;

    constructor() {
        this.certificate = new Certificate();
        this.courseType = new CourseType();
        this.courseCategory = new CourseCategory();
        this.courseStatus = new CourseStatus();
        this.courseOrganization = new CourseOrganization();
        this.tvatType = new VatTypes();
        this.lastIndex = 0;
        this.isPriceListShow = false;
        this.isSave = false;
    }
}
