import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { isNumber } from 'util';
@Pipe({
  name: 'novacurrency'
})
export class NovaCurrencyPipe implements PipeTransform {
  doNotShowAmountPermission = false;

  constructor(
    private currencyPipe: CurrencyPipe) {

  }

  transform(value: any, onlySymbol = false, isValuesNegative = false) {

    // comes from settings
    const currency = this.getAppCurrency();
    if (!currency) { return value; }

    let symbol = getCurrencySymbol(currency, 'wide');

    if (onlySymbol && currency) {
      switch (currency) {
        case 'PYG':
          symbol = '₲';
          break;
        case 'PAB':
          symbol = 'B/.';
          break;
        case 'CLP':
          symbol = '$';
          break;
        default:
          break;
      }
      return symbol;
    }

    if (value == '' || value == null || value == undefined) { value = 0; }

    const _value = parseFloat(value);
    if (isNaN(_value)) { return value; }

    value = _value;
    if (!isNumber(value)) { return value; }
    let currencyValue = value.toString();
    const decimalValue = this.toFixedNumber(Number(currencyValue));
    if (decimalValue !== undefined && decimalValue !== null) {

      // currency check EUR / USD / PYG / PAB / CLP
      if (currencyValue) {
        switch (currency) {
          case 'EUR':
            currencyValue = this.currencyPipe
              .transform(decimalValue, currency, 'symbol', '1.2-2', 'es');
            break;
          case 'USD':
            currencyValue = this.currencyPipe
              .transform(decimalValue, currency, 'symbol', '1.2-2', 'en');
            break;
          case 'PYG':
            currencyValue = this.currencyPipe
              .transform(decimalValue, currency, 'symbol', '1.2-2', 'en');
            currencyValue = currencyValue.replace(currency, '₲ ');
            break;
          case 'PAB':
            currencyValue = this.currencyPipe
              .transform(decimalValue, currency, 'symbol', '1.2-2', 'es-pa');
            currencyValue = currencyValue.replace(currency, ' B/.');
            break;
          case 'CLP':
            currencyValue = this.currencyPipe
              .transform(decimalValue, currency, 'symbol', '1.2-2', 'es-cl');
            currencyValue = currencyValue.replace(currency, '$ ');
            break;
          default:
            break;
        }
      }
    }

    if(this.doNotShowAmountPermission == true){ return '*'; }
    if(value == 0) { return currencyValue; }
    if(isValuesNegative) { return '-' + currencyValue;}

    return currencyValue;

  }

  toFixedNumber(x: number) {
    return x ? isNumber(x) ? Number(x.toFixed(2)) : Number(x) : 0;
  }

   getAppCurrency() {
    let appCurrency = 'EUR';
    if (sessionStorage.getItem('appCurrency')) {
      appCurrency = sessionStorage.getItem('appCurrency');
    }
    return appCurrency;
  }
}
