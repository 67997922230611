import { Component, Input, OnInit } from '@angular/core';
import { CompanyLogoConfiguration } from 'src/app/models/auxiliary/company-logo-configuration';
import { DoctorProfessionalAddress } from 'src/app/models/doctor/doctor-professional-address.model';
import { CommonDataService } from 'src/app/sections/common/services/common-data.service';

@Component({
  selector: 'app-receipt-report',
  templateUrl: './receipt-report.component.html',
  styleUrls: ['./receipt-report.component.scss']
})
export class ReceiptReportComponent implements OnInit {

  @Input() reportId: string;
  @Input() data;
  @Input() localeId: number;
  companyLogo: CompanyLogoConfiguration = null;

  date = new Date();
  constructor(public commonDataService: CommonDataService) {
    this.companyLogo = new CompanyLogoConfiguration();
  }

  ngOnInit() {
    this.commonDataService.getCompanyLogo().subscribe((labConfiguration: CompanyLogoConfiguration) => {
      this.companyLogo = labConfiguration;
    });
  }

  get doctorAddress(){
    return this.data && this.data.doctor && this.data.doctor.doctorProfessionalAddress.find(a=>a.isDefault) || new DoctorProfessionalAddress();
  }
}
