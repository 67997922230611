import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';
import { SweetAlertActions } from './../../constants/sweet-alert-actions';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService} from 'ngx-toastr';
import { User } from './../../models/user.model';
import { AuthService } from './../../sections/auth/services/auth.service';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FilterProfile } from './../../models/filterProfile.model';
import { FilterProfileService } from './../../sections/common/services/filter-profile.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-filter-profile-manager',
  templateUrl: './filter-profile-manager.component.html',
  styleUrls: ['./filter-profile-manager.component.scss']
})
export class FilterProfileManagerComponent implements OnInit {

  @Input() filters;
  @Input() module;
  @Output() updateFilters: EventEmitter<any> = new EventEmitter();
  @ViewChild('Modaladdeditprofile', { static: false }) modaladdeditprofile;

  filterProfiles: FilterProfile[];
  isLoading = false;
  isEdit = false;
  selectedFilterProfileId: number;
  selectedFilterProfile: FilterProfile;

  filterProfileForm: FormGroup = new FormGroup({});
  user: User;
  submitted: boolean;

  validationMessages = {  }

  constructor(private filterProfileService: FilterProfileService, private formBuilder: UntypedFormBuilder, private authService: AuthService,
    private translationService: TranslateService, private toastService: ToastrService, private commonUiService: CommonUIService) { }

  ngOnInit() {
    this.user = this.authService.getUserDetails() as User;
    this.initializeForm();
    this.getFilterProfilesList();
    this.submitted = false;
    this.selectedFilterProfileId = 0;
  }

  initializeForm() {
    this.submitted = false;
    this.filterProfileForm = this.formBuilder.group({
      userId: [this.user.id, [Validators.required]],
      profileName: [this.selectedFilterProfile ? this.selectedFilterProfile.profileName : null, [Validators.required]],
      filterJson: [JSON.stringify(this.filters), [Validators.required]],
      module: [this.module, [Validators.required]],
    });
  }

  getFilterProfilesList() {
    this.isLoading = true;
    this.filterProfileService.getUserFilterProfileList(this.user.id, this.module).subscribe(
      (data: any) => {
        this.filterProfiles = data._data;
        this.isLoading = false;
      },
      (error) => {
        this.failToast(error._message);
        this.isLoading = false;
      }
    );
  }

  handleFilterchange() {
    if (this.selectedFilterProfileId == 0) {
      this.updateFilters.emit(null);
      return;
    }
    this.selectedFilterProfile = this.filterProfiles.find(filterProfile => filterProfile.id == this.selectedFilterProfileId);
    if (this.selectedFilterProfile && this.selectedFilterProfile.filterJson) {

      this.updateFilters.emit(JSON.parse(this.selectedFilterProfile.filterJson));
    } else {
      this.updateFilters.emit(null);
    }
  }

  handleAdd() {
    this.isEdit = false;
    this.selectedFilterProfile = null;
    this.selectedFilterProfileId = 0;
    this.initializeForm();
    this.modaladdeditprofile.show();
  }

  handleEdit() {
    this.isEdit = true;
    this.initializeForm();
    this.modaladdeditprofile.show();
  }

  handleClose() {
    this.modaladdeditprofile.hide();
  }

  handleSave() {
    this.submitted = true;
    if (!this.commonUiService.validateForm(this.filterProfileForm, this.validationMessages)) {
      return;
    }
    if (!this.isEdit) {
      this.filterProfileService.saveFilterProfile(this.filterProfileForm.value).subscribe(
        (data: any) => {
          this.filterProfiles.push(data._data)
          this.successToast('common.savedSuccessfully');
          this.selectedFilterProfileId = data._data.id;
          this.handleFilterchange();
          this.handleClose();
          this.isLoading = false;
        },
        (error) => {
          this.failToast(error._message);
          this.isLoading = false;
        }
      );
    } else {
      this.filterProfileService.updateFilterProfile(this.selectedFilterProfileId, this.filterProfileForm.value).subscribe(
        (data: any) => {
          this.filterProfiles = this.filterProfiles.map(filterProfile => (filterProfile.id == this.selectedFilterProfileId ? data._data : filterProfile));
          this.successToast('common.updatedSuccessfully');
          this.handleClose();
          this.isLoading = false;
          this.isEdit = false;
        },
        (error) => {
          this.failToast(error._message);
          this.isLoading = false;
        }
      );
    }
  }

  handleDeleteItemClick(titleKey: string, messageKey: string, data: any) {
    this.commonUiService.sweetAlert(titleKey,messageKey,null,data).then((response: any) => {
      if (response.action == SweetAlertActions.confirm) this.handleDelete();
    })
  }

  handleDelete() {
    this.filterProfileService.deleteFilterProfile(this.selectedFilterProfileId).subscribe(
      () => {
        this.filterProfiles = this.filterProfiles.filter(filterProfile => (filterProfile.id != this.selectedFilterProfileId));
        this.selectedFilterProfileId = 0;
        this.handleFilterchange();
        this.successToast('common.deleteSuccessfully');
        this.handleClose();
        this.isLoading = false;
        this.isEdit = false;
      },
      (error) => {
        this.failToast(error._message);
        this.isLoading = false;
      }
    );
  }




  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  get profileName(){
    return this.filterProfileForm.get('profileName');
  }
}
