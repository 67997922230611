import { Town } from './doctor/doctor-town.model';
import { Country } from './country.model';
import { Province } from './doctor/province.model';
import { ClinicTimeTableModel } from './doctor/clinic-time-table.model';
import { BankTownModel } from './bank/bank-town.model';

export class Clinic {
  name: string;
  typeOfVia: string;
  nameOfVia: string;
  number: string;
  door: string;
  niforCif: string;
  address: string;
  userRido: string;
  priority: string;
  sanitaryNumber: string;
  includeInMailing: boolean;
  companyName: string;
  office: string;
  dc: string;
  account: string;
  street: string;
  floorNumberOfBuilding: string;
  postalCode: string;
  townId: number;
  provinceId: number;
  countryId: number;
  privateTelephone: string;
  workTelephone: string;
  mobileNumber: string;
  fax: string;
  email: string;
  web: string;
  clinicTimeTable: Array<ClinicTimeTableModel>;
  comodin1: string;
  comodin2: string;
  observations: string;
  country: Country;
  province: Province;
  town: Town;
  townName: string;
  id: number;
  bankEntity: string;
  bankName: string;
  bankAccountNumber: string;
  bankOfficeName: string;
  bankOfficeNumber: string;
  bankDc: string;
  bankAddress: string;
  bankPostalCode: string;
  bankTownId: number;
  bankProvinceId: number;
  bankCountryId: number;
  bankPrivateTelephone: string;
  bankWorkTelephone: string;
  bankMobileNumber: string;
  bankFax: string;
  bankEmail: string;
  bankWebSite: string;
  bankCountry: Country;
  bankProvice: Province;
  bankTown: BankTownModel;

  constructor() {
    this.town = new Town();
  }
}
