import { EmailConfigService } from './../../sections/auxillary-tables/smtp-configurations/services/email-config.service';
import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService} from 'ngx-toastr';
import { SendEmail } from 'src/app/models/common/SendEmail';
import { EmailService } from 'src/app/sections/common/services/email.service';

@Component({
  selector: 'app-email-send-modal',
  templateUrl: './email-send-modal.component.html',
  styleUrls: ['./email-send-modal.component.scss']
})
export class EmailSendModalComponent implements OnInit {


  @Input() attachments: { reportItd: string, attachmentName: string }[];
  @ViewChild('modalLargeNew', { static: false }) modal;

  formGroup: FormGroup;
  submitted = false;
  isLoading = false;

  senderEmailConfig = null;

  validationMessages = {  }

  constructor(
    private _fb: UntypedFormBuilder, private toastService: ToastrService, private emailConfigService: EmailConfigService,
    private translationService: TranslateService, private emailService: EmailService,
    private commonUIService: CommonUIService) {
  }

  ngOnInit() {
    this.initializeForm();
  }

  onClose() {
    this.modal.hide();
  }

  public show() {
    this.modal.show();
    this.submitted = false;
    this.initializeForm();
    this.getEmailConfig();
  }

  getEmailConfig() {
    this.emailConfigService.getEmailConfigList()
      .subscribe(
        (data: any) => {
          if (data._data != null && data._data.length > 0) {
            this.senderEmailConfig = data._data[0].email;
            this.initializeForm();
          }
        },
        (error) => {
          this.failToast(error._message);
        }
      );
  }

  initializeForm() {
    this.formGroup = this._fb.group({
      senderEmail: [this.senderEmailConfig, [Validators.required]],
      receiverEmail: [null, [Validators.required]],
      subject: [this.attachments ? this.attachments.map(m => m.attachmentName).join(' ,') : '', [Validators.required]],
      message: [this.attachments ? this.attachments.map(m => m.attachmentName).join(' ,') : '', [Validators.required]],
    });
  }

  removeAttachment(attachmentName) {
    this.attachments = this.attachments.filter(a => a.attachmentName != attachmentName);
  }

  async onSend() {
    this.submitted = true;
    if (!this.commonUIService.validateForm(this.formGroup, this.validationMessages)) {
      return;
    }
    this.isLoading = true;
    const sendEmail = this.formGroup.value as SendEmail;
    sendEmail.attachments = this.attachments.map(a => ({ base64String: a.reportItd, attachmentName: a.attachmentName }));
    await Promise.all(sendEmail.attachments.map(async (a, index) => {
      sendEmail.attachments[index].base64String = await this.commonUIService.ConvertHtmlToPdfBase64(a.base64String)
    }))

    this.emailService.sendEmail(sendEmail).subscribe(
      (_data: any) => {
        this.successToast('email_sent');
        this.returnResponse();
      },
      (error) => {

        this.isLoading = false;
        this.failToast(error._message);
      }
    );
  }

  private returnResponse() {
    this.isLoading = false;
    this.onClose();
  }

  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }


  get senderEmail() {
    return this.formGroup.get('senderEmail');
  }

  get receiverEmail() {
    return this.formGroup.get('receiverEmail');
  }

  get subject() {
    return this.formGroup.get('subject');
  }

  get message() {
    return this.formGroup.get('message');
  }


}
