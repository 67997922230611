import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Event } from '../../../../../models/dashboard/event.model';
import { EventType } from '../../../../../models/dashboard/event-type.model';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EventService } from '../services/event.service';

import { TranslateService } from '@ngx-translate/core';
import { AuxillaryService } from '../../../../../sections/common/services/auxillary.service';
import { AuthService } from '../../../../../sections/auth/services/auth.service';
import { changeNgbDateToCustomFormat, toNgbDate } from '../../../../../sections/common/services/change-ngb-date-to-custom-format';
import { SortList } from '../../../../../sections/common/sortList';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonUIService } from 'src/app/sections/common/services/common.ui.service';

@Component({
  selector: 'app-edit-events',
  templateUrl: './edit-events.component.html',
  styleUrls: ['./edit-events.component.scss']
})
export class EditEventsComponent implements OnInit {
  @ViewChild('modalLargeNew', { static: false }) child;

  @Output() close: EventEmitter<any> = new EventEmitter();

  submitted = false;
  event: Event;
  eventTypeList: EventType[];
  eventForm: FormGroup;
  loggedUserId = 0;
  isEdit: boolean;
  isLoading = false;
  isTimeRangeInvalid = false;

  validationMessages = {
    eventWebUrl : {
      pattern: 'urlpatterninvalid'
    }
   }

  constructor(private formBuilder: UntypedFormBuilder, private eventService: EventService, private auxillaryService: AuxillaryService,
    private toastService: ToastrService, private translationService: TranslateService, private authService: AuthService, private commonUiService: CommonUIService) {
    this.event = new Event();
    this.eventTypeList = [] as EventType[];
    this.initializeForAddEvent();
    const userDetails = this.authService.getTokenData();
    if (userDetails) {
      this.loggedUserId = userDetails.userId;
    }
  }

  ngOnInit() {
    this.getEventTypeList();
    this.checkIfEditOrAdd();
  }

  public show(eventData): void {
    this.child.show();
    this.submitted = false;
    this.isTimeRangeInvalid = false;
    this.event = eventData;
    this.eventForm.reset();
    this.checkIfEditOrAdd();
  }


  getEventTypeList() {
    this.auxillaryService.getEventTypeList().subscribe(
      (data: any) => {
        if (data._data != null) {
          this.eventTypeList = data._data.filter(x => x.status)
          this.eventTypeList = SortList(this.eventTypeList, 'eventTypeName');
        }
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  checkIfEditOrAdd() {
    if (!this.event.id) {
      this.isEdit = false;
      this.initializeForAddEvent();
      return;
    }
    this.isEdit = true;
    this.initializeForEditEvent();
  }

  initializeForAddEvent() {
    this.eventForm = this.formBuilder.group({
      id: [''],
      eventDate: ['', [Validators.required]],
      eventName: ['', [Validators.required]],
      eventTypeId: [0,[Validators.required]],
      startTime: [''],
      endTime: [''],
      eventDescription: ['', [Validators.maxLength(500)]],
      eventWebUrl: ['', [
        Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),
      ]],

    });
  }

  initializeForEditEvent() {
    const eventDate = moment(this.event.eventDate).format('YYYY-MM-DD');
    this.eventForm = this.formBuilder.group({
      id: [this.event.id],
      // eventDate: [eventDate, [Validators.required]],
      eventDate: [this.event.eventDate ? toNgbDate(new Date(this.event.eventDate)) : null, [Validators.required]],
      eventName: [this.event.eventName, [Validators.required]],
      eventTypeId: [this.event.eventTypeId,[Validators.required]],
      startTime: [this.event.startTime],
      endTime: [this.event.endTime],
      eventDescription: [this.event.eventDescription, [Validators.maxLength(500)]],
      eventWebUrl: [this.event.eventWebUrl, [
        Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),
      ]]
    });
  }

  onSave() {
    this.isTimeRangeInvalid = false;
    this.submitted = true;
    if (this.eventForm.controls.eventTypeId.value == 0) {
      this.eventForm.controls.eventTypeId.setValue(null);
    }
    if (!this.commonUiService.validateForm(this.eventForm, this.validationMessages)) {
      return;
    }
    const sTime = this.eventForm.value.startTime;
    const eTime = this.eventForm.value.endTime;
    if (sTime && eTime && sTime >= eTime) {
      this.commonUiService.showValidationError('eventStartTimeAndEndTimeRangeInvalidErrorMessage');
      this.isTimeRangeInvalid = true;
      return;
    }
    if (this.isEdit) {
      this.updateEvent(this.eventForm.value);
      return;
    }
    this.saveEvent(this.eventForm.value);
  }

  private returnResponse() {
    this.close.emit();
    this.child.hide();
    this.isLoading = false;
  }

  saveEvent(event: Event) {
    event.id = 0;
    event.entryDate = new Date();
    event.enteredBy = this.loggedUserId;
    this.isLoading = true;
    event.eventDate = changeNgbDateToCustomFormat(event.eventDate).toString();
    this.eventService.saveEvent(event).subscribe(
      (_data: any) => {
        this.successToast('saveEvent');
        this.returnResponse();
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  updateEvent(event: Event) {
    event.id = this.event.id;
    event.modifiedDate = new Date();
    event.modifiedBy = this.loggedUserId;
    event.enteredBy = this.event.enteredBy;
    event.entryDate = this.event.entryDate;
    this.isLoading = true;
    event.eventDate = changeNgbDateToCustomFormat(event.eventDate).toString();
    this.eventService.updateEvent(event.id, event).subscribe(
      (_data: any) => {
        this.successToast('updateEvent');
        this.returnResponse();
      },
      (error) => {
        this.failToast(error._message);
      }
    );
  }

  successToast(messageTranslationKey) {
    this.translationService.get(['success', messageTranslationKey]).subscribe((translations: any) => {
      this.toastService.success(translations[messageTranslationKey], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }

  failToast(message) {
    this.translationService.get(['errorTitle', message]).subscribe((translations: any) => {
      this.toastService.error(translations[message], translations.success, {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-bottom-right', // Adjust the position as needed
      });
    });
  }


  get eventTypeId() {
    return this.eventForm.get('eventTypeId');
  }

  get eventName() {
    return this.eventForm.get('eventName');
  }

  get eventDate() {
    return this.eventForm.get('eventDate');
  }

  get startTime() {
    return this.eventForm.get('startTime');
  }

  get endTime() {
    return this.eventForm.get('endTime');
  }

  get eventWebUrl() {
    return this.eventForm.get('eventWebUrl');
  }

  get eventDescription() {
    return this.eventForm.get('eventDescription');
  }


}
