import { EventType } from './event-type.model';

export class Event {
    id: number;
    eventTypeId: number;
    eventDate: any;
    eventName: string;
    startTime: Date;
    endTime: Date;
    entryDate: Date;
    modifiedDate: Date;
    enteredBy: number;
    modifiedBy: number;
    sTime: string;
    eTime: string;
    eventType: EventType;
    eventDescription: string;
    eventWebUrl: string;

    constructor() {
        this.eventType = new EventType();
    }
}