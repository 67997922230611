import { Injectable } from '@angular/core';
import { IssuerEndPoints } from 'src/app/constants/api-endpoints';
import { map } from 'rxjs/operators';
import { Issuer } from 'src/app/models/auxiliary/issuer.model';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/sections/common/services/api.service';


@Injectable({
  providedIn: 'root'
})
export class IssuerService {
  IssuerUrls = IssuerEndPoints;

  constructor(private _apiService: ApiService) { }

  private returnResponse(response) {
    if (response._statusCode > 399) {
      throw (response);
    }
    return response;
  }

  saveIssuer(resolutionIssue: Issuer): Observable<Issuer> {
    return this._apiService.post(this.IssuerUrls.saveIssuer, resolutionIssue, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateIssuer(id: number, resolutionIssue: Issuer): Observable<Issuer> {
    return this._apiService.put(this.IssuerUrls.updateIssuer + id, resolutionIssue, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  deleteIssuer(id: number): Observable<Issuer> {
    return this._apiService.delete(this.IssuerUrls.deleteIssuer, id.toString(), true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  updateDefaultIssuer(id: number): Observable<Issuer> {
    return this._apiService.put(this.IssuerUrls.updateDefaultIssuer.replace("{id}", id.toString()), null, true, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getDefaultIssuer(): Observable<Issuer> {
    return this._apiService.get(this.IssuerUrls.getDefaultIssuer, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

  getIssuersList(): Observable<Issuer[]> {
    return this._apiService.get(this.IssuerUrls.getAllIssuerList, true).pipe(
      map((response: any) => {
        return this.returnResponse(response);
      }));
  }

}
