import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuardService} from './sections/auth/services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./sections/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./sections/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./sections/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'doctor',
    loadChildren: () => import('./sections/doctor/doctor.module').then(m => m.DoctorModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'main-page',
    loadChildren: () => import('./sections/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'clinic-page',
    loadChildren: () => import('./sections/clinics/clinics.module').then(m => m.ClinicsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'correspondence-page',
    loadChildren: () => import('./sections/correspondence/correspondence.module').then(m => m.CorrespondenceModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'auxillary-tables',
    loadChildren: () => import('./sections/auxillary-tables/auxillary-tables.module').then(m => m.AuxillaryTablesModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'course-page',
    loadChildren: () => import('./sections/courses/courses.module').then(m => m.CoursesModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'bank-page',
    loadChildren: () => import('./sections/bank-details/bank-details.module').then(m => m.BankDetailsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'provider-page',
    loadChildren: () => import('./sections/provider-details/provider-details.module').then(m => m.ProviderDetailsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'council-resolution-page',
    loadChildren: () => import('./sections/council-resolutions/council-resolutions.module').then(m => m.CouncilResolutionsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'juditial-resolution-page',
    loadChildren: () => import('./sections/juditial-resolutions/juditial-resolutions.module').then(m => m.JuditialResolutionsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'recall-page',
    loadChildren: () => import('./sections/recalls/recalls.module').then(m => m.RecallsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'expenses-page',
    loadChildren: () => import('./sections/expenses/expenses.module').then(m => m.ExpensesModule),
    canActivate: [AuthGuardService]
  }
  ,
  {
    path: 'activity-page',
    loadChildren: () => import('./sections/activity/activity.module').then(m => m.ActivityModule),
    canActivate: [AuthGuardService]
  }
  ,
  {
    path: 'society-page',
    loadChildren: () => import('./sections/society/society.module').then(m => m.SocietyModule),
    canActivate: [AuthGuardService]
  } ,
  {
    path: 'payments-page',
    loadChildren: () => import('./sections/payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'invoices-page',
    loadChildren: () => import('./sections/invoice/invoice.module').then(m => m.InvoiceModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'receipts-page',
    loadChildren: () => import('./sections/receipt/receipt.module').then(m => m.ReceiptModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'college-data-page',
    loadChildren: () => import('./sections/college-data/college-data.module').then(m => m.CollegeDataModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'events-page',
    loadChildren: () => import('./sections/events/events.module').then(m => m.EventsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'prescriptions-page',
    loadChildren: () => import('./sections/prescriptions/prescriptions.module').then(m => m.PrescriptionsModule),
    canActivate: [AuthGuardService]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
