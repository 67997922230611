import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { AuthService } from '../sections/auth/services/auth.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpLoaderSpinnerService } from '../shared/loader-spinner/http-loader-spinner/service/http-loader-spinner.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private loaderService: HttpLoaderSpinnerService, private router: Router) {
  }

  count = 0;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getAccessToken()}`
      }
    });

    if (this.auth.isLogin) {
      if (!this.checkIsAuthenticated()) {
        this.router.navigate(['login']);
      }
    }

    this.showLoader();
    return next.handle(request)
    .pipe(finalize(() => {
        this.onEnd();       
      }));
  }

  checkIsAuthenticated() {
    return this.auth.isAuthenticated();
  }

  private onEnd(): void {
    if (this.count >= 0) {
      this.count--;
    }
    if (this.count === 0) {
      this.hideLoader();
    }
  }

  private showLoader(): void {
    this.count++;
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
