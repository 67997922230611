import { CorrespondenceDocument } from './correspondence-document.model';
import { ViaType } from '../auxiliary/via-type.model';

export class Correspondence {
    id: number;
    code: string;
    registeredDate: Date;
    date: Date;
    origin: string;
    description: string;
    viaTypeId: string;
    isEntry: boolean;
    status: boolean;
    createdDate: Date;
    createdBy: number;
    modifiedDate: Date;
    modifiedBy: number;
    datestring: any;
    documentsCount: number;

    viaType: ViaType;
    correspondenceDocument: CorrespondenceDocument[];

    constructor() {
      this.viaType = new ViaType();
      this.correspondenceDocument = [] as CorrespondenceDocument[];
    }

}
