import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  BASE_URL: string;

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {}

  async setBaseUrl() {
    if (sessionStorage.getItem('c2VydmVyLW9yaWdpbg')) {
      this.BASE_URL = atob(sessionStorage.getItem('c2VydmVyLW9yaWdpbg'));
    } else {
      try {
        const config = await this.getJSON().toPromise();
        if (config) {
          this.BASE_URL = config.base_url;
          sessionStorage.setItem('c2VydmVyLW9yaWdpbg', btoa(config.base_url));
        } else {
          sessionStorage.clear();
          localStorage.clear();
          this.router.navigate(['/']);
          throw new Error('Config file is not found!');
        }
      } catch (error) {
        console.error('Error loading config:', error);
      }
    }
  }

  private getJSON(): Observable<any> {
    return this.httpClient.get('app/config.json');
  }

  get(url: string, isAuthorizationIncluded: boolean = false, mapJson = true) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (isAuthorizationIncluded) {
      headers = headers.set('Authorization', `Bearer ${sessionStorage.getItem('access-token')}`);
      headers = headers.set('Permissions', sessionStorage.getItem('auth-permissions'));
    } else {
      headers = headers.set('Permissions', sessionStorage.getItem('auth-permissions'));
    }

    const options = { headers };

    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }
    url = url.replace('//', '/').replace('///', '/');

    const uri = `${this.BASE_URL}${url}`;

    if (mapJson) {
      return this.httpClient
        .get(uri, options)
        .pipe(
          map(response => response as any),
          catchError(err => of(err))
        );
    } else {
      return this.httpClient.get(uri, options)
        .pipe(catchError(err => of(err)));
    }
  }

  getUrlEncoded(url: string, model: any, isAuthorizationIncluded = false, mapJson = true) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (isAuthorizationIncluded) {
      headers = headers.set('Authorization', `Bearer ${sessionStorage.getItem('access-token')}`);
    }

    const options = { headers };

    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }
    let params = new HttpParams();
    for (const key in model) {
      if (model.hasOwnProperty(key)) {
        params = params.set(key, model[key]);
      }
    }
    const query = params.toString().replace('+', '%20');
    const uri = `${this.BASE_URL}${url}?${query}`;

    if (mapJson) {
      return this.httpClient
        .get(uri, options)
        .pipe(
          map(response => response as any),
          catchError(err => of(err))
        );
    } else {
      return this.httpClient.get(uri, options)
        .pipe(catchError(err => of(err)));
    }
  }

  post(url: string, data?: any, mapJson: boolean = true, isAuthorizationIncluded: boolean = false) {

    const premissions = sessionStorage.getItem('auth-permissions');
    const headers = { 
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': this.BASE_URL,
        'Permissions': `${premissions}`}  
      
    if (isAuthorizationIncluded) {
      headers['Authorization'] = `Bearer ${sessionStorage.getItem('access-token')}`;
    }
    const options = { headers };
    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }

    if (mapJson) {
      return this.httpClient.post(`${this.BASE_URL}${url}`, data, {'headers':headers})
        .pipe(catchError(_err => of('error')));
    } else {
      return this.httpClient.post(`${this.BASE_URL}${url}`, data, {'headers':headers});
    }
  }

  delete(url: string, id: string, isAuthorizationIncluded: boolean = false) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Permissions': sessionStorage.getItem('auth-permissions')
    });

    if (isAuthorizationIncluded) {
      headers = headers.set('Authorization', `Bearer ${sessionStorage.getItem('access-token')}`);
    }
    const options = { headers };

    url = url.endsWith('/') ? url : url + '/';
    return this.httpClient.delete(`${this.BASE_URL}${url}${id}`, options)
      .pipe(catchError(_err => of('error')));
  }

  put(url: string, data?: any, mapJson: boolean = true, isAuthorizationIncluded: boolean = false) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': this.BASE_URL,
      'Permissions': sessionStorage.getItem('auth-permissions')
    });

    if (isAuthorizationIncluded) {
      headers = headers.set('Authorization', `Bearer ${sessionStorage.getItem('access-token')}`);
    }
    const options = { headers };
    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }

    if (mapJson) {
      return this.httpClient.put(`${this.BASE_URL}${url}`, JSON.stringify(data), options)
        .pipe(catchError(_err => of('error')));
    } else {
      return this.httpClient.put(`${this.BASE_URL}${url}`, data, options);
    }
  }

  upload(url: string, formData: FormData, isAuthorizationIncluded: boolean = false): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = new HttpHeaders();
    if (isAuthorizationIncluded) {
      headers = headers.set('Authorization', `Bearer ${sessionStorage.getItem('access-token')}`);
    }
    const params = new HttpParams();
    const options = {
      params: params,
      reportProgress: true,
      headers: headers
    };
    const req = new HttpRequest('POST', `${this.BASE_URL}${url}`, formData, options);
    return this.httpClient.request(req);
  }
}
