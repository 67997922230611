import { IOption } from './i-option';

export class DropDownOption implements IOption {
    value: any;
    label: string;
    disabled?: boolean;

    constructor(_value?: any, _label?: string, _disabled?: boolean) {
        this.value = _value;
        this.label = _label;
        this.disabled = _disabled;
    }
}