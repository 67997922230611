export class CommonFunctions {

  /**
   * get browser's locale
   */
  static GetBrowserLocale() {
    return navigator.language;
  }

}
