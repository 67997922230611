export class User {
  id: number;
  username: string;
  password: string;
  roleid: number;
  email: string;
  passwordExpiryDate: string;
  token: string;
  roleName: string;
  status: number;
  recentPasswords:string;
  rolePermisionList: [];
  isRemember: boolean;
  iSLogin: boolean;
}
